var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-manage" }, [
    _vm._m(0),
    _c("div", { staticClass: "container pb-5 p-3" }, [
      _c(
        "div",
        { staticClass: "row text-center mt-4" },
        _vm._l(_vm.blogData, function(item) {
          return _c("div", { staticClass: "col-6 col-md-3 mb-3" }, [
            _c(
              "a",
              {
                staticClass: "blog-text-color",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.getBlogLink(item.date.replace(/-/g, ""))
                  }
                }
              },
              [
                _c("div", { staticClass: "imgWrap" }, [
                  _c("img", {
                    staticClass: "blog-thumbnail-img",
                    attrs: { src: _vm.makeImgPath(item.imagePath) }
                  })
                ]),
                _c(
                  "div",
                  { staticClass: "text-left py-1 px-2 blog-info-title" },
                  [_vm._v(_vm._s(item.title))]
                ),
                _c("div", { staticClass: "text-left px-2" }, [
                  _vm._v(_vm._s(item.date.replace(/-/g, "/")))
                ]),
                _c("div", { staticClass: "text-left px-2 title-text" }, [
                  _vm._v(_vm._s(item.articleTop))
                ])
              ]
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "blog-info-topImg" } }, [
      _c("h1", { staticClass: "blog-top-text text-center text-white mt-0" }, [
        _vm._v("ブログ一覧")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }