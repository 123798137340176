<template lang="pug">
  .user-manage
    #blog-info-topImg
      h1.blog-top-text.text-center.text-white.mt-0 ブログ一覧
    .container.pb-5.p-3
      .row.text-center.mt-4
        .col-6.col-md-3.mb-3(v-for="item in blogData")
          a.blog-text-color(href="#" @click.prevent="getBlogLink(item.date.replace(/-/g, ''))")
            div.imgWrap
              img.blog-thumbnail-img(:src="makeImgPath(item.imagePath)")
            .text-left.py-1.px-2.blog-info-title {{item.title}}
            .text-left.px-2 {{item.date.replace(/-/g, '/')}}
            .text-left.px-2.title-text {{item.articleTop}}

</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      blogData:null,
    }
  },
  created() {
    this.getBlog()
  },
  methods: {
    // ブログ記事データ取得
    getBlog() {
      axios.get(process.env.VUE_APP_API_GATEWAY + '/api-blog', {
        params: {
          "type": "",
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.blogData = res.data
      })
      .catch(err => {
        console.log(err)
      })
    },

    //遷移リンク先
    getBlogLink(path) {
      this.$router.push({ name: 'blog_:date' , params: { date: path } })
      // return "/blog/" + path
    },

    //画像パス取得
    makeImgPath(imgPath){
      return process.env.VUE_APP_MATERIAL_URL + imgPath
    },
  },
}
</script>

<style lang="stylus">
body
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif
  letter-spacing: .1rem
  line-height: 1.7
  background-color: #ffffff

a
  color: #2980e8

  &:hover
    opacity: .8
    text-decoration: none

#blog-info-topImg
  background-image: url(/img/blog/blog_top_img.jpg)
  height: 310px
  width: 100%
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  letter-spacing: .4rem
  line-height: 2
  z-index: 1
  position: relative
  opacity: .8
.blog-text-color
  color: #2B546A

  &:hover
    text-decoration: none
    color: #2B546A

.blog-top-text
  padding-top: 140px

.page-back
  font-weight: bold
  color: #2B546A

.blog-thumbnail-img
  height 97px
  width 100%
  object-fit: cover;
  border-radius: 10px

.blog-info-title
  font-weight: bold
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

.title-text
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

@media screen and (min-width: 575.98px)
  .blog-thumbnail-img
    height 179.25px
    width 239px
@media screen and (min-width: 767px)
  .blog-thumbnail-img
    height 112.13px
    width 149.5px
@media screen and (min-width: 991.98px)
  .blog-thumbnail-img
    height 157.13px
    width 209.5px
@media screen and (min-width: 1199.98px)
  .blog-thumbnail-img
    width 254.5px
    height 190.88px
</style>